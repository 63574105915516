import { LINKS } from '@utils/constants';
import { StaticQuery, graphql } from 'gatsby';
import React from 'react';

import Content from '@components/Layout/Content/Content';
import Grid from '@components/Layout/Grid/Grid';
import StripCard from '@components/Molecule/StripCard/StripCard';
import Marker from '@components/SVG/Marker';
import Copy from '@components/Typography/Copy';
import H from '@components/Typography/H';

const JobListing = () => {
	return (
		<StaticQuery
			query={graphql`
				query {
					job: allPrismicJob {
						edges {
							node {
								uid
								data {
									summary
									title {
										text
									}
									location
									content {
										html
									}
								}
							}
						}
					}
				}
			`}
			render={({ job }: { job: any }): JSX.Element => {
				return (
					<div>
						<Grid>
							{job.edges.map(
								({ node }: { node: any }, key: number) => {
									const { uid } = node;
									const { title, location, summary } =
										node.data;

									return (
										<StripCard
											id={key}
											to={`${LINKS.CAREERS}/${uid}`}
											title={title}
											key={key}>
											<Content spacing="sm">
												{location && (
													<div className="row">
														<Marker />
														<Copy
															font="sm"
															weight="bold"
															spacing="none">
															{location}
														</Copy>
													</div>
												)}

												{title?.text && (
													<H
														level={4}
														as="h3"
														spacing="lg">
														{title?.text}
													</H>
												)}

												{summary && (
													<Copy>{summary}</Copy>
												)}
											</Content>
										</StripCard>
									);
								}
							)}
						</Grid>
					</div>
				);
			}}
		/>
	);
};

export default JobListing;
