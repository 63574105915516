import cx from 'classnames';
import React from 'react';

import * as styles from './Lead.module.css';

const Lead = ({
	theme = 'blue',
	children,
	as: Component = 'div',
	className = '',
}: {
	theme?: 'white' | 'blue';
	children: React.ReactNode;
	as?: React.ElementType;
	className?: string;
}): JSX.Element => {
	return (
		<Component
			className={cx(styles.lead, {
				[styles[`${theme}Theme`]]: theme,
				[className]: !!className,
			})}>
			{children}
		</Component>
	);
};

export default Lead;
