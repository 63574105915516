import React from 'react';

const Marker = ({ className = '' }: { className?: string }) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="19.393"
			height="25.857"
			viewBox="0 0 19.393 25.857"
			className={className}
			style={{ maxWidth: '1.25rem' }}
		>
			<path
				d="M8.7,25.335C1.362,14.7,0,13.606,0,9.7a9.7,9.7,0,0,1,19.393,0c0,3.909-1.362,5-8.7,15.639a1.213,1.213,0,0,1-1.993,0Zm1-11.6A4.04,4.04,0,1,0,5.656,9.7,4.04,4.04,0,0,0,9.7,13.736Z"
				fill="#f36d2d"
			/>
		</svg>
	);
};

export default Marker;
