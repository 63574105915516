import { graphql } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';
import React from 'react';

import Hero from '@components/Hero/Hero';
import Columns from '@components/Layout/Columns/Columns';
import Content from '@components/Layout/Content/Content';
import Lead from '@components/Layout/Lead/Lead';
import PageSection from '@components/Layout/PageSection/PageSection';
import JobListing from '@components/Listing/JobListing';
import Page from '@components/Master/Page';
import SEO from '@components/Master/SEO';
import { MotionCopy } from '@components/Typography/Copy';
import { MotionH } from '@components/Typography/H';

const CareersPage = ({ data }: { data: any }) => {
	return (
		<Page>
			<SEO
				title="Careers | UHV Design"
				description="We're always looking for qualified engineering, scientific and technical personnel to help us grow. Looking for an exciting new role? Get in touch."
			/>

			<Hero
				image={{
					src: getImage(data.hero),
					alt: 'Engineers working on a UHV device',
				}}
				type="box">
				<div>
					<MotionH level={2} as="h1">
						Careers
					</MotionH>
				</div>

				<Content>
					<MotionH level={3} as="h2">
						A growing company
					</MotionH>

					<MotionCopy spacing="sm">
						We are a dynamic and growing company and we're always
						looking for highly qualified engineering, scientific and
						technical personnel to support our ambitious plans for
						growth.
					</MotionCopy>

					<MotionCopy>
						You must have the flexibility to work on a number of
						projects simultaneously, ranging from project
						definition, including customer liaison, through to
						technical development and deployment.
					</MotionCopy>

					<MotionCopy>
						If you are interested in working for UHV Design, please
						browse our open positions below. If there are currently
						no positions that match your criteria, please send your
						CV along with introductory covering letter to
						<a href="mailto:sales@uhvdesign.com">
							sales@uhvdesign.com
						</a>
						.
					</MotionCopy>
				</Content>
			</Hero>

			<PageSection background="grey">
				<Columns stretch="latter">
					<Lead>
						<MotionH level={3}>Job opportunities</MotionH>
					</Lead>

					<JobListing />
				</Columns>
			</PageSection>
		</Page>
	);
};

export const query = graphql`
	query {
		hero: file(relativePath: { eq: "careers/hero.jpg" }) {
			childImageSharp {
				gatsbyImageData
			}
		}
	}
`;

export default CareersPage;
